<template>
<div v-if="loading">
    <Loading :active.sync="loading" color="#042446"></Loading>
</div>
<div class="scope" :style="theme" v-else>
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true" :no-close-on-backdrop="true">
        <Login />
    </b-modal>
    <b-modal v-model="showBuyChapterModal" :hide-header="true" :hide-footer="true" :no-close-on-backdrop="true">
        <BuyChapterModal :novel="detailForModal" @closeModal="showBuyChapterModal=false;checkLogin()" />
    </b-modal>

    <div class="chapter text-center" v-if="isLoadData" style="background:white">
        <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
    </div>

    <div class="chapter" :style="readNovelStyle" v-else>
        <b-row v-if="headdetail.status == '200'">
            <b-col col-md="10" cols="10">
                <div>
                    <a-icon type="left" class="backToNovel mr-2" @click="backToNovel()" />
                    <a-select
                        label-in-value
                        :default-value="{ key: $route.params.chapter_id }"
                        class="chapter_select"
                        size="large"
                        @change="goToChapter"
                    >
                        <a-select-option v-for="(chapter, index) in chapterDetail.chapter_list" :key="index" :value="chapter.chapter_id">
                            ตอนที่ {{chapter.chapter_ep}} : {{chapter.chapter_name}}
                        </a-select-option>
                    </a-select>
                </div>
            </b-col>
            <b-col class="chapter_read_config">
                <b-icon-gear-fill v-if="canChangeTheme" font-scale="1.5" v-b-toggle.setting style="float:right" class="settingBtn"></b-icon-gear-fill>
            </b-col>
            <b-collapse id="setting" class="text-center" style="width: 100%;">
                <b-card class="mt-2" style="border-radius: 8px;">
                    <b-row>
                        <b-col cols="12" sm="3">
                            <span class="notChangeTextColor mr-2">Font:</span>
                            <b-form-select size="sm" v-model="readNovelFontSize" :options="options" style="width:50%"></b-form-select>
                        </b-col>
                        <b-col cols="6" sm="3" class="margin">
                            <span class="notChangeTextColor mr-2" style="vertical-align: top;">Background:</span>
                            <v-swatches v-model="colorBg" popover-x="left" swatches="text-advanced" :trigger-style="triggerStyle"></v-swatches>
                        </b-col>
                        <b-col cols="6" sm="3" class="margin">
                            <span class="notChangeTextColor mr-2" style="vertical-align: top;">Text:</span>
                            <v-swatches v-model="colorText" popover-x="left" swatches="text-advanced" :trigger-style="triggerStyle"></v-swatches>
                        </b-col>
                        <b-col cols="12" sm="3">
                            <span @click="setToDefault" class="default">Default</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-collapse>
        </b-row>
        <b-container v-else>

        </b-container>

        <a-divider class="divider" />
        <div class="readNovel noselect" :style="readNovelFont" v-html="chapterDetail.chapter_detail"></div>
        <div class="end text-center mt-4">--- จบตอน ---</div>
    </div>
    <b-row class="mt-3 mb-3">
        <b-col>
            <div class="backNextBtn" @click="goToBefore" v-if="headdetail.before!=null">
                <b-icon-arrow-left-short font-scale="1.5"></b-icon-arrow-left-short>
                <span class="backNextText">ตอนที่แล้ว</span>
            </div>
        </b-col>
        <b-col>
            <div class="backNextBtn" style="float:right" @click="goToAfter" v-if="headdetail.after!=null">
                <span class="backNextText">ตอนถัดไป</span>
                <b-icon-arrow-right-short font-scale="1.5"></b-icon-arrow-right-short>
            </div>
        </b-col>
    </b-row>
    <Comment type="chapter" v-if="headdetail.status=='200'" :id="headdetail.now.chapter_id" />
</div>
</template>

<script>
import axios from 'axios'
import router from '../../router';
import VSwatches from 'vue-swatches'
import Login from './Login'
import BuyChapterModal from './../elements/BuyChapterModal'
import 'vue-swatches/dist/vue-swatches.css'
import Comment from "./../elements/comment/Comment"
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    data() {
        return {
            isLoadData: false,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            showLoginModal: false,
            showBuyChapterModal: false,
            detailForModal: {},
            chapterDetail: [],
            headdetail: {
                before: {},
                now: {},
                after: {},
            },
            pathNow: window.location.pathname.substring(0, (window.location.pathname).lastIndexOf("/")),
            colorBg: localStorage.getItem('Background_color') != null ? localStorage.getItem('Background_color') : '#ffffff',
            colorText: localStorage.getItem('Text_color') != null ? localStorage.getItem('Text_color') : '#505050',
            readNovelFontSize: localStorage.getItem('Fontsize') != null ? localStorage.getItem('Fontsize') : '16px',
            readNovelBg: localStorage.getItem('Background_color') == '#D4E6ED' ? "#ffffff" : "rgba(255,255,255,0.1)",
            triggerStyle: {
                width: '30px',
                height: '30px',
                borderRadius: '5px',
                border: '1px solid #ced4da'
            },
            options: [],
            isLogin: localStorage.getItem('UserInfo') != null,
            canChangeTheme: true
        };
    },
    metaInfo() {
        return { 
            title: "ตอนที่ " + this.headdetail.now.chapter_ep+": "+this.headdetail.now.chapter_name+ " | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย",
            meta: [
                { name: 'description', content:  'NovelRealm นิยายแปลที่ถูกคัดสรรมาอย่างดี การันตีด้วยยอดผู้อ่านกว่าล้านครั้ง ทั้งนิยายแฟนตาซี นิยายกำลังภายใน นิยายรักโรแมนติก'},
                { property: 'og:title', content: "ตอนที่ " + this.headdetail.now.chapter_ep+": "+this.headdetail.now.chapter_name+ " | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย"}
            ]
        }
    },
    components: {
        VSwatches,
        Login,
        BuyChapterModal,
        Comment
    },
    computed: {
        ...mapGetters({
            loading: 'GET_LOADING',
            mode: 'GET_MODE'
        }),
        readNovelStyle() { //read novel bg
            return {
                "background-color": this.readNovelBg,
                "color": this.colorText
            };
        },
        theme() { //page bg
            // if (this.mode == "darkmode") {
            //     return {
            //         "background-color": "#000000",
            //     };
            // } else {
                return {
                    "background-color": this.colorBg,
                };
            // }
        },
        readNovelFont() {
            return {
                "font-family": 'Sarabun',
                "font-size": this.readNovelFontSize
            };
        }
    },
    watch: {
        colorBg() {
            if (this.colorBg == '#000000') {
                this.colorText = '#ffffff'
            } else if (this.colorBg == '#D4E6ED') {
                this.readNovelBg = '#ffffff'
            } else {
                this.readNovelBg = 'rgba(255,255,255,0.1)'
            }
            localStorage.setItem('Background_color', this.colorBg)
            localStorage.setItem('Text_color', this.colorText)
        },
        readNovelFontSize() {
            localStorage.setItem('Fontsize', this.readNovelFontSize)
        },
        colorText() {
            localStorage.setItem('Text_color', this.colorText)
        },
        mode() {
            this.checkMode()
        },
        $route(){
            this.SET_LOADING_STATUS(true)
            this.showLoginModal = false
            this.showBuyChapterModal = false
            this.checkLogin()
            if(this.isLogin){
                this.workerupdateUser()
            }
            this.manageFont()
            this.checkMode()
        }
    },
    mounted() {
        this.SET_LOADING_STATUS(true)
        this.checkLogin()
        this.manageFont()
        this.checkMode()
    },
    methods: {
        ...mapActions(['SET_LOADING_STATUS']),
        checkLogin() {
            const formData = new FormData();
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
            }
            formData.append('chapter_id', this.$route.params.chapter_id);
            this.getHeadDetail(formData)
        },
        goToChapter(value){
            router.push(this.pathNow + "/" + value.key)
            document.body.scrollTop = 0; //go to top
            document.documentElement.scrollTop = 0; //go to top
            //this.checkLogin()
        },
        async workerupdateUser() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios.post('https://api2.novelrealm.com/module/profile&submodule=info', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            this.SET_USER_INFO({
                                auth_token: this.userInfo.auth_token,
                                auth_memberid: this.userInfo.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async getHeadDetail(formData) {
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=headdetail', formData)
                    .then((res) => {
                        this.headdetail = res.data
                        //console.log(this.headdetail)
                        if(this.headdetail.status == "404"){
                            //console.log("DELETE")
                            this.notfound()
                        }
                        else{
                            //document.title = "ตอนที่ " + this.headdetail.now.chapter_ep + ": " + this.headdetail.now.chapter_name + " | NovelRealm"
                            if (this.isLogin == false) {
                                if (this.headdetail.now.chapter_price != "0") {
                                    this.showLoginModal = true
                                }
                                else{
                                    this.checkBuyChapter(this.headdetail.now, formData)
                                }
                            } else {
                                this.checkBuyChapter(this.headdetail.now, formData)
                            }
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async checkBuyChapter(novel, formData) {
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buycheck', formData)
                    .then((res) => {
                        if (res.data.status == "404") {
                            this.detailForModal = {
                                chapter_id: novel.chapter_id,
                                last_ep: novel.chapter_ep,
                                chapter_name: novel.chapter_name,
                                chapter_price: novel.chapter_price,
                                novel_cover: "",
                                novel_id: novel.chapter_novel_id,
                                member_gold: this.userInfo.member_gold,
                                closeModal: true
                            }
                            this.chapterDetail = []
                            this.showBuyChapterModal = true
                            this.isLoadData = false
                            this.SET_LOADING_STATUS(false)
                            window.scrollTo(0,0);
                        }
                        else{
                            this.getChapterDetail(formData)
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async getChapterDetail(formData) {
            this.isLoadData = true
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=reader', formData)
                    .then((res) => {
                        this.chapterDetail = res.data
                        this.isLoadData = false
                        this.SET_LOADING_STATUS(false)
                        window.scrollTo(0,0);
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        goToBefore() {
            router.push(this.pathNow + "/" + this.headdetail.before.chapter_id)
            document.body.scrollTop = 0; //go to top
            document.documentElement.scrollTop = 0; //go to top
            this.checkLogin()
        },
        goToAfter() {
            router.push(this.pathNow + "/" + this.headdetail.after.chapter_id)
            document.body.scrollTop = 0; //go to top
            document.documentElement.scrollTop = 0; //go to top
            this.checkLogin()
        },
        notfound(){
            router.replace("/404")
            document.body.scrollTop = 0; //go to top
            document.documentElement.scrollTop = 0; //go to top
        },
        manageFont() {
            var font = 12
            for (var n = 0; n <= 13; n++) {
                font = font + 2
                this.options.push({
                    value: font + 'px',
                    text: font + 'px'
                })
            }
        },
        setTheme() {
            var Background_color = localStorage.getItem('Background_color')
            var Text_color = localStorage.getItem('Text_color')
            var Fontsize = localStorage.getItem('Fontsize')
            if (Background_color == null || Text_color == null || Fontsize == null) {
                Background_color = "#D4E6ED"
                Text_color = "#505050"
                Fontsize = "16px"
            } else {
                this.colorBg = Background_color
                this.colorText = Text_color
                this.readNovelFontSize = Fontsize
            }
        },
        setToDefault() {
            this.colorBg = "#D4E6ED"
            this.colorText = "#505050"
            this.readNovelFontSize = "16px"
        },
        backToNovel() {
            router.push('/novel/' + this.headdetail.now.chapter_novel_id)
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            this.canChangeTheme = true
        },
        darkmode() {
            this.colorBg = "#000000"
            this.readNovelBg = "rgba(255,255,255,0.1)"
            this.colorText = "#ffffff"
        }
    }
};
</script>

<style scoped>
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scope {
    margin: 3% 10% 3% 10%;
}

.chapter {
    padding: 2% 2% 2% 2%;
    border: 2px solid #ffffff;
}

.backToNovel {
    color: #EE806B;
    font-size: 24px;
    transform: translateZ(0px);
    transition-duration: .5s;
}

.backToNovel:hover {
    color: #EE806B;
    cursor: pointer;
    transform: scale(1.2);
}

.chapterName {
    font-weight: bold;
    font-size: 18px;
}

.backNextBtn {
    background-color: #F5D087;
    border-radius: 6px;
    padding: 4px 0px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    width: 7em;
}

.backNextBtn:hover {
    background-color: #FAC762;
    cursor: pointer;
}

.backNextText {
    vertical-align: top
}

.form-control:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.notChangeTextColor {
    color: #505050;
}

.default {
    background-color: #F5D087;
    cursor: pointer;
    border-radius: 7px;
    padding: 5.5px 10px;
    text-align: center;
    color: #ffffff;
}

.default:hover {
    font-weight: bold;
    background-color: #FAC762;
}

.settingBtn:focus {
    outline: 0 !important;
}

.settingBtn:hover {
    cursor: pointer;
    color: #000;
}

.readNovel>>>p {
    /*margin-bottom: 0 !important;*/
    font-weight: 400;
}

.chapter_select{
    width: 500px;
}

.chapter_read_config{
    align-items: center;
    display: flex;
}

.darkmode .settingBtn{
    color: #aaa;
}

.darkmode .settingBtn:hover{
    color: #fff;
}


@media only screen and (max-width: 1000px) {
    .scope {
        padding: 4%;
        margin: 0px;
        /* margin: 2% 2% 0.5% 2%; */
    }

    .divider {
        margin: 8px 0;
    }

    .chapterName {
        font-size: 14px;
    }

    .readNovel {
        font-size: 14px;
    }

    .end {
        font-size: 13px;
    }

    .card-body {
        padding: 20px 5px
    }

    .margin {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .chapter_select{
        width: calc(100% - 32px);
    }
}

</style>
